/**
* React root
*/
#root {
	min-height: 100%;
	height: 100%;
}

/**
* Basic HTML Element Selectors
*/
html, body {
	background-color: #000;
	color: white;
	min-height: 100%;
	height: 100%;
	overflow-y: hidden;
}

h1, h2, h3 {
	font-family: 'JetBrains Mono', Courier, monospace;
	font-weight: 80;
	text-align: center;
	color:  white;
}

p {
	text-align: left;
}

a, p, li, label, button {
	font-family: Epilogue, Roboto, Arial, sans-serif;
	line-height: 1.5;
}

button {
	cursor: pointer;
}

a {
	cursor: pointer;
	color: hotpink;
	text-decoration: none;
}

main {
	flex: 4 1;
	overflow-y: auto;
	padding-left: 50px;
	padding-right: 50px;
	width: 100%;
}

/* 900px lines up with "md" in material ui */
@media only screen and (max-width: 900px) {
	main {
		padding-left: 10px;
		padding-right: 10px;
	}
}

/**
* Utility Classes
*/
.flex {
	display: flex;
}

.flex-no-wrap {
	flex-wrap: nowrap;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.full-width {
	width: 100%;
}

.full-height {
	height: 100%;
}
