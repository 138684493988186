.home-main {
    width: 100%;
    overflow: hidden;
}

.home-content {
	display: flex;
	align-items: center;
	flex-direction: column;
    overflow: hidden;
    width: 100%;
    padding: 10px;
}

.home-credit {
	position: fixed;
	bottom: 0;
}

.tentacles {
    margin: -90px 0 0 -70px;
    -webkit-animation:spin 20s linear infinite;
    -moz-animation:spin 20s linear infinite;
    animation:spin 20s linear infinite;
    z-index: 998;
    overflow-x:hidden;
    overflow-y:hidden;
}
@-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform:rotate(360deg);
    }
}
